/**
 * Generic module to keep the class file clean. will split later on somewhere
 */
import {BigNumber, Contract } from 'ethers';
import ERC20 from '../ERC20';
/**
 * Wrapper object for ethers Contract objects.
 */
 export class ContractWrapper {
  constructor(
    public name: string,
    public address: string,
    public contract: Contract,
  ) {}
}

/**
 * Wrapper object for ERC20 objects
 */
export class ERC20Wrapper {
  constructor(
    public symbol: string,
    public token: ERC20,
  ) {}
}


/** remove the initial underscore for some of our values we're looking for */
export const stripUnderscore = (str:string): string => str.startsWith('_') ? str.slice(1) : str
/** normalize a string (ideally name/symbol) to match up the internal handling */
export const normalizeString = (str: string): string => stripUnderscore(str).toLowerCase()

export const bigToNumber = (val: BigNumber, decimals: number|ERC20): number => (
  // Number(val.div(BigNumber.from(10).pow(typeof decimals === 'number' ? decimals : decimals.decimal)))
  Number(val) / (10 ** (typeof decimals === 'number' ? decimals : decimals.decimal))
)
