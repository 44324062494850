import { BigNumber } from 'ethers';
import ERC20 from '../zomb-finance/ERC20';

export const getDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 4,
  isTruncated: boolean = false,
) => {
  if (decimals === 0) {
    fractionDigits = 0;
  }
  const number = getBalance(balance, decimals - fractionDigits);
  const ret = (number / 10 ** fractionDigits).toFixed(fractionDigits);
  if (ret.length > 12 && isTruncated) {
    return ret.slice(0, 12) + '...';
  }
  return ret;
};

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, isTruncated = false) => {
  return getDisplayBalance(balance, decimals, 4, isTruncated);
};

export const getFullDisplayBalanceLong = (balance: BigNumber, decimals = 18, isTruncated = false) => {
  return getDisplayBalance(balance, decimals, 10, isTruncated);
};

export function getBalance(balance: BigNumber, decimals = 18): number {
  return Number(balance.div(BigNumber.from(10).pow(decimals)));
}

export function getTokenAmountAsNumber(amount: BigNumber|string|number, token: ERC20): number;
export function getTokenAmountAsNumber(amount: BigNumber|string|number, decimals: number): number;
/**
 * Convert a BigNumber like value to a readable actual value with decimal places
 * @param amount amount of tokens to evaluate, either a number/string or BigNumber
 * @param td either an ERC20 describing the token or the number of decimal places
 * @returns Number with decimals
 */
export function getTokenAmountAsNumber(amount: BigNumber|string|number, td: number|ERC20): number {
  const _amount = Number(amount);
  let decimal: number;
  if (typeof td == 'number') {
    decimal = td;
  } else if (td instanceof ERC20) {
    decimal = td.decimal;
  }
  else {
    throw new Error('Wrong type of decimal/token value provided')
  }
  return _amount / (10 ** decimal)
}

