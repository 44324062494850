// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './zomb-finance/config';
import { BankInfo } from './zomb-finance';

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./zomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      FUSDT: ['0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6], // This is actually usdc on mainnet not fusdt
      // BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      // ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      // SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      // TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      USDC: ['0x04068da6c83afcfa0e13ba15a6696662335d5b75', 6],
      // WETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18],
      ZOMB: ['0x1e6B8866Ff62De4894C92eF454799d299Abd1F2D', 18],
      _3OMB: ['0x14DEf7584A6c52f470Ca4F4b9671056b22f4FfDE', 18],
      'USDT-FTM-LP': ['0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c', 18],
      'USDT-3OMB-LP': ['0x3D15e910F4d554beFF658D9429AA3f56A623934B', 18],
      'ZOMB-3OMB-LP': ['0xf5D13b8fEfBE99467496182Eb95e6fA4ae02501A', 18],
      'zSHARE-USDC-LP': ['0x4B89694A314869c3Db90AA9eF41eDaDE403e4245', 18],
      'ZOMB-USDC-LP': ['0xF8C1c9D0D01A7e34EC7540cD4fB180f6a431D870', 18],
      'ZOMB-ZSHARE-LP': ['0xfA53130CF647faA2f54Bc91cb00B51e269491E13', 18],
      // REDEMPTION V2
      'ZOMB-3OMB R-LP': ['0x7bCd268C0B38E99fb0e8E322608c25A6D8D5315b', 18],
      'zSHARE-USDC R-LP': ['0x9d3bad1981130052f03315f61f3df76ef58dee1b', 18],
      'ZOMB-USDC R-LP': ['0x77dD3aC37457818529Ea580DD0EB1BD2C08ACb4b', 18],
      'ZOMB-ZSHARE R-LP': ['0x72B80Ed8fe614344C9f5Ac236941101AD1b8c13a', 18],
    },
    baseLaunchDate: new Date('2022-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2022-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2022-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  development: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./zomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      FUSDT: ['0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6], // This is actually usdc on mainnet not fusdt
      // BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      // ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      // SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      // TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      USDC: ['0x04068da6c83afcfa0e13ba15a6696662335d5b75', 6],
      // WETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18],
      ZOMB: ['0x1e6B8866Ff62De4894C92eF454799d299Abd1F2D', 18],
      _3OMB: ['0x14DEf7584A6c52f470Ca4F4b9671056b22f4FfDE', 18],
      'USDT-FTM-LP': ['0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c', 18],
      'USDT-3OMB-LP': ['0x3D15e910F4d554beFF658D9429AA3f56A623934B', 18],
      'ZOMB-3OMB-LP': ['0xf5D13b8fEfBE99467496182Eb95e6fA4ae02501A', 18],
      'zSHARE-USDC-LP': ['0x4B89694A314869c3Db90AA9eF41eDaDE403e4245', 18],
      'ZOMB-USDC-LP': ['0xF8C1c9D0D01A7e34EC7540cD4fB180f6a431D870', 18],
      'ZOMB-ZSHARE-LP': ['0xfA53130CF647faA2f54Bc91cb00B51e269491E13', 18],
      // REDEMPTION V2 --- NOT testnet addresses
      'ZOMB-3OMB R-LP': ['0x7bCd268C0B38E99fb0e8E322608c25A6D8D5315b', 18],
      'zSHARE-USDC R-LP': ['0x9d3bad1981130052f03315f61f3df76ef58dee1b', 18],
      'ZOMB-USDC R-LP': ['0x77dD3aC37457818529Ea580DD0EB1BD2C08ACb4b', 18],
      'ZOMB-ZSHARE R-LP': ['0x72B80Ed8fe614344C9f5Ac236941101AD1b8c13a', 18],
    },
    baseLaunchDate: new Date('2022-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2022-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2022-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding ZOMB
        - 2 = LP asset staking rewarding zSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  Zomb3ombLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-3OMB LP',
    poolId: 0,
    sectionInUI: 4,
    contract: 'Zomb3ombLPZShareRewardPool',
    depositTokenName: 'ZOMB-3OMB-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 5,
    closedForStaking: true,
  },
  // ZombZShareRewardPool: {
  //   name: 'Earn zSHARE by ZOMB',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'ZombZShareRewardPool',
  //   depositTokenName: 'ZOMB',
  //   earnTokenName: 'zSHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
  ZshareUSDCLPZShareRewardPool: {
    name: 'Earn zSHARE by zSHARE-USDC LP',
    poolId: 2,
    sectionInUI: 4,
    contract: 'ZshareUSDCLPZShareRewardPool',
    depositTokenName: 'zSHARE-USDC-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 8,
    closedForStaking: true,
  },
  ZombUSDCLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-USDC LP',
    poolId: 3,
    sectionInUI: 4,
    contract: 'ZombUSDCLPZShareRewardPool',
    depositTokenName: 'ZOMB-USDC-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 7,
    closedForStaking: true,
  },
  ZombZshareLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-ZSHARE LP',
    poolId: 4,
    sectionInUI: 4,
    contract: 'ZombZshareLPZShareRewardPool',
    depositTokenName: 'ZOMB-ZSHARE-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 6,
    closedForStaking: true,
  },
  // REDEMPTION
  Zomb3ombRLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-3OMB R-LP',
    poolId: 9,
    sectionInUI: 4,
    contract: 'Zomb3ombRLPZShareRewardPool',
    depositTokenName: 'ZOMB-3OMB R-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 1,
    closedForStaking: true,
  },
  ZshareUSDCRLPZShareRewardPool: {
    name: 'Earn zSHARE by zSHARE-USDC R-LP',
    poolId: 10,
    sectionInUI: 4,
    contract: 'ZshareUSDCRLPZShareRewardPool',
    depositTokenName: 'zSHARE-USDC R-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 4,
    closedForStaking: true,
  },
  ZombUSDCRLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-USDC R-LP',
    poolId: 11,
    sectionInUI: 4,
    contract: 'ZombUSDCRLPZShareRewardPool',
    depositTokenName: 'ZOMB-USDC R-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 2,
    closedForStaking: true,
  },
  ZombZshareRLPZShareRewardPool: {
    name: 'Earn zSHARE by ZOMB-ZSHARE R-LP',
    poolId: 12,
    sectionInUI: 4,
    contract: 'ZombZshareRLPZShareRewardPool',
    depositTokenName: 'ZOMB-ZSHARE R-LP',
    earnTokenName: 'zSHARE',
    finished: false,
    sort: 3,
    closedForStaking: true,
  },
};

export default configurations['production'];
