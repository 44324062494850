export const ZOMB_TICKER = 'ZOMB';
export const zSHARE_TICKER = 'zSHARE';
export const zBOND_TICKER = 'zBOND';
export const FTM_TICKER = 'FTM';
export const WFTM_TICKER = 'WFTM';
export const THREEOMB_TICKER = '_3OMB';
export const USDC_TICKER = 'USDC';

// LPs symbols
export const ZOMB_3OMB = 'ZOMB-3OMB R-LP';
export const ZSHARE_USDC = 'zSHARE-USDC R-LP';
export const ZOMB_USDC = 'ZOMB-USDC R-LP';
export const ZOMB_ZSHARE = 'ZOMB-ZSHARE R-LP';

export const SYMBOLS = {
  ZOMB: ZOMB_TICKER,
  ZSHARE: zSHARE_TICKER,
  ZBOND: zBOND_TICKER,
  FTM: FTM_TICKER,
  WFTM: WFTM_TICKER,
  THREEOMB: THREEOMB_TICKER,
  USDC: USDC_TICKER,
  ZOMB_3OMB,
  ZSHARE_USDC,
  ZOMB_USDC,
  ZOMB_ZSHARE,
}

// name of the zapper contract from deployment file
export const ZAPPER_CONTRACT = 'zapper';

export const SPOOKY_ROUTER_ADDR = '0xF491e7B69E4244ad4002BC14e878a34207E38c29';
export const ZAPPER_ROUTER_ADDR = '0x8bb1fe7a50082623b372d857b4d07911c786a3e9';
export const TAX_OFFICE_ADDR = '0xcaf6C0FB8Bcb737C2D5D7e5Db615147a26091De1';

export const ADDRESS = {
  SPOOKY_ROUTER: SPOOKY_ROUTER_ADDR,
  ZAPPER_ROUTER: ZAPPER_ROUTER_ADDR,
  TAX_OFFICE: TAX_OFFICE_ADDR,
}

export const CONTRACTS = {
  ZAPPER: ZAPPER_CONTRACT,
}
