import { Provider, TransactionResponse } from '@ethersproject/providers';
import { BigNumber, Contract, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

// import { Fetcher as FetcherSpirit, Token as TokenSpirit } from '@spiritswap/sdk';
import { ChainId, Fetcher, Pair, Price, Token, TokenAmount } from '@spookyswap/sdk';

import { getDefaultProvider } from '../../utils/provider';
import { Configuration } from '../config';
import ERC20 from '../ERC20';

import { ContractWrapper, ERC20Wrapper, normalizeString, stripUnderscore, bigToNumber } from './helpers'
import * as types from './types';
import { ADDRESS, CONTRACTS } from '../../utils/constants';

import type { ZombFinance } from '../ZombFinance'

/**
 * Work in progress replacement for ZombFinance. For now it will be similarly built
 * (i.e. everything dumped in) and will just handle LPs and zap functionality.
 * Later on every responsability should/could be split into separate components
 * to allow easier and collaborative work.
 *
 * Since this is a WIP this currently contains all new Contract and ERC20 objects
 * so it doesn't have to rely on external sources (ZombFinance) and can normalize
 * and more easily handle fetching the data without errors.
 * Token (ERC20) and Contracts should have their own separate managers that handle
 * all the logic about registering and retrieving the data so we won't care about
 * anything all around the codebase.
 * 
 * Also the whole interface for this should be obviously moved away in a separate
 * file. will do this when i'm good with how it works.
 */
export class FinanceManagerV2 {
  private chainId: ChainId;
  /** Web3Provider object we're using */
  private provider: ethers.providers.Web3Provider;
  /** Contracts from the configuration, wrapped for handling */
  private contracts: ContractWrapper[] = [];
  /** ERC20 Token objects from configuration, wrapped for handling */
  private tokens: ERC20Wrapper[] = [];
  
  constructor(cfg: Configuration, private zombFinance?: ZombFinance) {
    this.provider = getDefaultProvider();
    this.chainId = cfg.chainId;

    this.__registerContracts(cfg, this.provider);
    this.__registerTokens(cfg, this.provider);
  }

  public getAccount(): string { return this.zombFinance?.getAccount(); }

  /** returns true if the given symbol (or erc20 symbol) matches an LP format */
  public isLP(token: string|ERC20): boolean {
    const tokenName = typeof token === 'string' ? token : token.symbol;
    // return this.__splitLpSymbol(tokenName).length === 3;
    return tokenName.endsWith('lp') || tokenName.endsWith('LP')
  }

  public getProvider(): Provider { return this.provider; }

  /**
   * Try and get a Contract by name or address
   * @param nameOrAddress name (const) or address of the contract
   * @returns Contract object or null if not found
   */
  public getContract(nameOrAddress: string): Contract | null {
    let contractWrapper: ContractWrapper = null;

    const loopContracts = (wrapper: ContractWrapper) => {
      if (wrapper.name === normalizeString(nameOrAddress))
        return wrapper.contract;
      else if (wrapper.address === nameOrAddress)
        return wrapper.contract;
      return null;
    }
    contractWrapper = this.contracts.find(loopContracts)

    return contractWrapper?.contract;
  }

  public getToken(nameOrAddress: string): ERC20 | null {
    for (const wrapper of this.tokens) {
      if (wrapper.symbol === normalizeString(nameOrAddress))
        return wrapper.token;
      if (wrapper.token.address === nameOrAddress)
        return wrapper.token;
    }
    return null
  }

  public isNativeToken(token: ERC20): boolean {
    // TODO: generalize. this only works for fantom for now, but i have no idea
    // how to check if a token is a native token with what we have.
    // an idea would be to get a map of addresses {chainId: address} and match with
    // those with the currently used chainId (or pass it down as arg)
    return token.address === this.getToken('wftm').address;
  }

  /**
   * Given either the name, address or ERC20 of an LP token or
   * two tokens returns the matching LP token ERC20 object if it exists.
   */
  public getLPToken(nameOrAddress: string): ERC20 | null;
  public getLPToken(tokenA: string|ERC20, tokenB?:string|ERC20): ERC20 | null {
    // get and normalize the name of the token
    const getSymbol = (arg: string|ERC20) => normalizeString(arg instanceof ERC20 ? arg.symbol : arg);
    // check match between name and (a || b) for random ordered args in lp name
    const isMatch = (name: string, a: string, b: string) => name === a || name === b;

    if (!tokenB && this.isLP(tokenA)) {
      // working with only nameOrAddress, meaning we passed the LP name
      return this.getToken(getSymbol(tokenA));
    }
    
    const sym1 = getSymbol(tokenA);
    const sym2 = getSymbol(tokenB);
    if (sym1 === sym2)
      throw new Error(`Can't look up LP with two of the same tokens: ${sym1} - ${sym2}`)

    for (const wrapper of this.tokens) {
      if (!this.isLP(wrapper.symbol))
        continue;

      const [first, second] = this.__splitLpSymbol(wrapper.symbol);
      if (isMatch(first, sym1, sym2) && isMatch(second, sym1, sym2)) {
        return wrapper.token;
      }

    }
  }

  public async fetchPairData(token0: string|ERC20, token1: string|ERC20): Promise<Pair> {
    if (!this.provider?.ready)
      return;

    if (typeof token0 === 'string') token0 = this.getToken(token0);
    if (typeof token1 === 'string') token1 = this.getToken(token1);

    return Fetcher.fetchPairData(
      new Token(this.chainId, token0.address, token0.decimal),
      new Token(this.chainId, token1.address, token1.decimal),
      this.provider
    )
  }

  /**
   * Given a token and an optional other token to get the price in, return the
   * price of a single token. If no `priceIn` token is provided that will be in
   * an USD equivalent (USDC)
   * @param token token to get the price of
   * @param priceIn token to get the price IN
   * @returns price of one token
   */
  public async getTokenPrice(token: ERC20, priceIn: ERC20 = this.getToken('USDC')): Promise<number> {
    if (!this.provider?.ready) return;

    if (this.isLP(token) || this.isLP(priceIn)) {
      throw new Error('Cannot use getTokenPrice to price an LP. getLPStats to get all the info.')
    }
    try {
      const pairData = await this.fetchPairData(token, priceIn);
      // find the correct token in the Price object. order might be different so
      // so can't rely on it being the first
      const priceData: Price = token.address === pairData.token0.address
        ? pairData.token0Price
        : pairData.token1Price;
  
      return this.evalPairFractionValue(priceData)

    } catch(e) {
      console.error(e)
      // Spooky SDK (our current used Fetcher) throws an invariant error when
      // both token and priceIn are the same, obviously. price matches, return 1.
      // see https://github.com/SpookySwap/spookyswap-sdk/blob/master/src/entities/token.ts#L39
      return 1;
    }
  }

  /**
   * Given an LP Token return the two ERC20 object representing the tokens in the LP.
   * 
   * NOTE: Tokens may not be in the same order as they are registered in the LP.
   * if that  is needed use the sortTokens method
   * 
   *    const [token0, token1] = obj.sortTokens(...obj.getTokensFromLP(addr0, addr1));
   * 
   * @param lpToken LP to get the tokens from
   * @returns array of 2 ERC20 token objects
   */
  getTokensFromLP(lpToken: string|ERC20): [ERC20, ERC20] {
    const symbol = typeof lpToken === 'string' ? lpToken : lpToken.symbol;
    const _validate = (token: ERC20, name: string) => {
      if (!token) throw new Error(`Cannot find ERC20 obj for ${name}`)
    }

    if (!this.isLP(lpToken)) {
      throw new Error (`${symbol} is not an LP Token!`)
    }
    const [token0name, token1name] = this.__splitLpSymbol(normalizeString(symbol));
    const [token0, token1] = [this.getToken(token0name), this.getToken(token1name)];
    _validate(token0, token0name)
    _validate(token1, token1name)
    return this.sortTokens(token0, token1)
  }

  /**
   * Retrieve the stats the that UI needs to show for the given LP token
   * @param lpToken LPToken to get the stats of
   */
  public async getLPStats(lpToken: ERC20): Promise<types.LPStats> {
    if (!this.isLP(lpToken))
      throw new Error(`${lpToken.symbol} is not an LP`);

    const tokenInfo = async (_token: ERC20) => {
      // tokens in current LP, token price (default -- usdc)
      return [
        bigToNumber(await _token.balanceOf(lpToken.address), _token) / lpSupply,
        await this.getTokenPrice(_token)
      ]
    }

    /** total LP tokens existent */
    const lpSupply = bigToNumber(await lpToken.totalSupply(), lpToken.decimal);
    const [tokenA, tokenB] = this.getTokensFromLP(lpToken);
    const [tAinLp, tAprice] = await tokenInfo(tokenA);
    const [tBinLp, tBprice] = await tokenInfo(tokenB);

    // amount of token in lp * price for both tokens.
    const lpPrice = (tAinLp * tAprice) + (tBinLp * tBprice);
    const lpLiquidity = lpPrice * lpSupply;

    return {
      totalSupply: lpSupply,
      totalLiquidity: lpLiquidity,
      price: lpPrice,
      tokens: [
        { token: tokenA, amount: tAinLp, price: tAprice },
        { token: tokenB, amount: tBinLp, price: tBprice }
      ]
    };
  }

  /**
   * Try and get an estimate to a zap-in operation into an LP with the given
   * zapToken amount through the specified router.
   * return value contains [error, estimate]. Error for now is a simple boolean
   * but has been set up to allow a c-style return (false|0 -> ok, true|number -> error code)
   * @param zapToken token to zap with
   * @param lpToken lp token to zap into
   * @param zapAmount how many zapToken we're using
   * @param _router router to use (constants, name)
   * @returns array with [error, ZapEstimates]. error is `false` if request succeded.
   */
  async estimateZapIn(zapToken: ERC20, lpToken: ERC20, zapAmount: string|number = '0', _router = ADDRESS.SPOOKY_ROUTER): Promise<[boolean, types.ZapEstimate]> {
    const zapper = this.getContract(CONTRACTS.ZAPPER);
    if (!zapper) throw new Error("Zapper contract not found")
    // return value creation helpers, because yes.
    function makeTokenData(t:ERC20, a:BigNumber): types.ZapEstimateToken { return {token: t, amount: bigToNumber(a, t)} }
    const makeRetVal = (t0a:BigNumber, t1a:BigNumber): types.ZapEstimate => ({
      lpToken, lpAmount, tokens: [makeTokenData(token0, t0a), makeTokenData(token1, t1a)]
    });

    zapAmount = String(zapAmount)
    let [token0, token1] = this.getTokensFromLP(lpToken);
    let lpAmount: number;
    // contract has some poor exception handling and some arrive here.
    try {
      /** @dev not related for zomb but we might need to use another method to zap native token (ftm) */
      const [token0Amnt, token1Amnt] = await zapper.estimateZapInToken(
        zapToken.address, lpToken.address, _router, parseUnits(zapAmount, zapToken.decimal));
      /**
       * @dev dinghino - don't understand the logic but it seems to work?
       * old code took the amount of estimated token0 divided by token1 in one LP.
       */ 
      const lpStats = await this.getLPStats(lpToken);
      lpAmount = bigToNumber(token0Amnt, token0) / lpStats.tokens[1].amount;
      // lpAmount = bigToNumber(token0Amnt, token0) * lpStats.tokens[0].amount;
      const result = makeRetVal(token0Amnt, token1Amnt);
      
      return [false, result]

    } catch(e) {
      console.error(e)
      return [true, makeRetVal(BigNumber.from(0), BigNumber.from(0))];
    }
  }

  async zapIn(zapToken: string|ERC20, lpToken: string|ERC20, amount: string|number): Promise<TransactionResponse> {
    const account = this.getAccount()
    if (!account) {
      console.error('Wallet is not unlocked');
      return;
    }
    const zapper = this.getContract(CONTRACTS.ZAPPER);
    lpToken = lpToken instanceof ERC20 ? lpToken : this.getLPToken(lpToken);
    zapToken = zapToken instanceof ERC20 ? zapToken : this.getToken(zapToken);

    const amntBN = parseUnits(String(amount), zapToken.decimal)

    // NOTE: Old codebase had this split and our zapper contract handles these
    // cases separately, so to be sure for now i'm splitting the zap call.
    // we should check if it's actually needed contract side and/or adjust the contract
    // to use a single interface, because this way is dumb.
    if (this.isNativeToken(zapToken)) {
      zapper.zapIn(lpToken.address, ADDRESS.SPOOKY_ROUTER, account,
        // this i have no idea WHY but it's in the old codebase. doesn't match
        // contract zapIn signature though, so... wut?
        {value: amntBN})
    }

    return zapper.zapInToken(
      zapToken.address, amntBN, lpToken.address,
      ADDRESS.SPOOKY_ROUTER, account,
    );
  }

  /**
   * Sort two ERC20 objects using spookyswap sdk API and their sort functionality
   * to be used when we need to have tokens sorted properly (like matching
   * contract responses) but don't have the correct order.
   * @returns sorted tokens
   */
  public sortTokens(token0: ERC20, token1: ERC20): [ERC20, ERC20] {
    const _t0 = new Token(this.chainId, token0.address, token0.decimal);
    const _t1 = new Token(this.chainId, token1.address, token1.decimal);
    if (_t0.sortsBefore(_t1))
      return [token0, token1]
    return [token1, token0]
  }

  // ==========================================================================
  // Private utils

  private evalPairFractionValue(data: TokenAmount|Price): number {
    let decimalNumberator:number, decimalDenominator:number = 0;
    
    // TODO: Ugly. do it properly :(
    if (data instanceof TokenAmount) {
      decimalNumberator = (data as TokenAmount).currency.decimals;
      decimalDenominator =(data as TokenAmount).token.decimals;
    } else if (data instanceof Price) {
      decimalNumberator = (data as Price).quoteCurrency.decimals;
      decimalDenominator =(data as Price).baseCurrency.decimals;
    } 
    return (
      (Number(data.numerator) / (10 ** decimalNumberator)) /
      (Number(data.denominator) / (10 ** decimalDenominator))
    );
  }

  private __splitLpSymbol(name: string): string[] {
    return name.split('-').map(i => i.split(' ')).reduce((p, c) => [...p, ...c], []) 
  }

  private __registerContracts(cfg: Configuration, provider: Provider) {
    // clean up odd characters, like for _3OMB
    for (const [name, deployment] of Object.entries(cfg.deployments)) {
      this.contracts.push(new ContractWrapper(
        normalizeString(name),
        deployment.address,
        new Contract(deployment.address, deployment.abi, provider)
      ))
    }
  }
  private __registerTokens(cfg: Configuration, provider: Provider) {
    for (const [symbol, [address, decimals]] of Object.entries(cfg.externalTokens)) {
      this.tokens.push(new ERC20Wrapper(
        normalizeString(symbol),
        // strip is due to 3omb being _3OMB and using the token.symbol as displayName
        new ERC20(address, provider, stripUnderscore(symbol), decimals),
      ))
    }

    // FIXME: zshare is apparently missing from externalTokens (duh) while the
    // other internal tokens (i.e. zomb) are not. this should be fixed somehow,
    // ALL tokens should be in the same place, who cares if they are internal or not.
    const { zShare } = cfg.deployments;
    this.tokens.push(new ERC20Wrapper(
      normalizeString('zSHARE'),
      new ERC20(zShare.address, provider, 'zSHARE'),
    ))
  }
  
  // This is kind of imprecise and the same work gets done in getLPStats but more
  // accurate. we could either uncomment and leave it if we want it quick'n'dirty
  // or delete.
  // /**
  //  * Given an LP token evaluate it's price from its parts.
  //  * @param lpToken ERC20 object for the LP to evaluate
  //  * @returns price in USDC
  //  */
  // public async getLpPrice(lpToken: ERC20): Promise<number> {

  //   // get one of the tokens in the LP (from the symbol for now) and use that
  //   // to get its price and double it (since LPs are balanced in value)
  //   const tokenA = this.getToken(this.__splitLpSymbol(normalizeString(lpToken.symbol))[0]);
  //   const lpSupply = await lpToken.totalSupply();
  //   const tokenBalance = await tokenA.balanceOf(lpToken.address);
  //   // how many for each LP
  //   const tokenInOneLp = Number(tokenBalance) / Number(lpSupply);
  //   return (tokenInOneLp * 2 * await this.getTokenPrice(tokenA));
  // }

}

export default FinanceManagerV2;