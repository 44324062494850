// import { Fetcher, Route, Token } from '@uniswap/sdk';
// import { Fetcher as FetcherSpirit, Token as TokenSpirit } from '@spiritswap/sdk';
import { Fetcher, Route, Token } from '@spookyswap/sdk';
import { Configuration } from './config';
import { ContractName, TokenStat, AllocationTime, LPStat, Bank, PoolStats, ZShareSwapperStat } from './types';
import { BigNumber, Contract, ethers, EventFilter } from 'ethers';
import { decimalToBalance } from './ether-utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getFullDisplayBalance, getDisplayBalance, getFullDisplayBalanceLong } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';
import moment from 'moment';
import { parseUnits } from 'ethers/lib/utils';
import { SYMBOLS, ADDRESS } from '../utils/constants';

import FinanceManagerV2 from './FinanceManagerV2';
import { bigToNumber, normalizeString } from './FinanceManagerV2/helpers';

interface ContractsRefs {
  [name: string]: Contract;
}
/**
 * Evaluate the given externalTokens (from config) and return a map with the
 * tokens' names (instead of the deployments names) and their contract objects
 * for future use.
 * TODO: This is prone to bugs. contracts refs should be held somewhere else (too)
 * inside the config
 * this is a temporary solution until we start refactoring and normalize all names
 * and refs
 * @param externalTokens tokens to evaluate to create the contract objects
 * @param _interface ethers ContractInterface
 * @param provider
 * @returns object containing all and only the LPs contract we have in our config file
 */
function createLpContracts(
  externalTokens: { [contractName: string]: [string, number] },
  _interface: ethers.ContractInterface,
  provider: ethers.providers.Provider,
): ContractsRefs {
  const retval: ContractsRefs = {};

  for (const [symbol, [addr]] of Object.entries(externalTokens)) {
    if (!symbol.endsWith('-LP')) continue;
    retval[symbol] = new Contract(addr, _interface, provider);
  }
  return retval;
}

/**
 * An API module of Zomb Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class ZombFinance {
  myAccount: string;
  isBlacklisted: boolean;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: ContractsRefs;
  lpContracts: ContractsRefs;
  externalTokens: { [name: string]: ERC20 };
  masonryVersionOfUser?: string;

  ZOMBWFTM_LP: Contract;
  ZOMB: ERC20;
  zSHARE: ERC20;
  zBOND: ERC20;
  FTM: ERC20;
  _3OMB: ERC20;
  v2: FinanceManagerV2;

  constructor(cfg: Configuration) {
    this.v2 = new FinanceManagerV2(cfg, this);

    (window as any).zomb = this;

    const { deployments, externalTokens } = cfg;
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    this.ZOMB = new ERC20(deployments.zomb.address, provider, 'ZOMB');
    this.zSHARE = new ERC20(deployments.zShare.address, provider, 'zSHARE');
    this.zBOND = new ERC20(deployments.zBond.address, provider, 'zBOND');
    this._3OMB = new ERC20(deployments._3omb.address, provider, '3OMB');
    this.FTM = this.externalTokens['WFTM'];

    // Uniswap V2 Pair
    this.ZOMBWFTM_LP = new Contract(externalTokens['ZOMB-3OMB-LP'][0], IUniswapV2PairABI, provider);

    // NOTE: Temporary ref until we can properly map deployments (our this.contracts)
    // to the names we use all around the UI (config.externalTokens names)
    this.lpContracts = createLpContracts(externalTokens, IUniswapV2PairABI, provider);

    this.config = cfg;
    this.provider = provider;
    this.isBlacklisted = false;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [this.ZOMB, this.zSHARE, this.zBOND, ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    this.ZOMBWFTM_LP = this.ZOMBWFTM_LP.connect(this.signer);
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    this.fetchMasonryVersionOfUser()
      .then((version) => (this.masonryVersionOfUser = version))
      .catch((err) => {
        console.error(`Failed to fetch masonry version: ${err.stack}`);
        this.masonryVersionOfUser = 'latest';
      });
    // initial blacklist verification
    this.verifyBlacklisted(account);
  }

  getAccount(): string {
    return this.myAccount;
  }

  getToken(symbol: string): ERC20 | null {
    return this.v2.getToken(symbol);
  }

  /**
   * get the Contract object for the given LP Symbol.
   * @param symbol LP Symbol to get the LP of
   */
  getLpContract(symbol: string): Contract | null {
    return this.v2.getContract(symbol);
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  /**
   * Validate the wallet against the blacklist address, set and return the state
   * @param account address to check
   * @returns bool true if the wallet is connected and blacklisted.
   */
  async verifyBlacklisted(account: string): Promise<boolean> {
    if (!account || !this.isUnlocked) {
      this.isBlacklisted = false;
    } else {
      this.isBlacklisted = await this.contracts.blacklist.verifyBacklistedUser(account);
    }
    return this.isBlacklisted;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //===================FROM SPOOKY TO DISPLAY =========================
  //=========================IN HOME PAGE==============================
  //===================================================================

  async getZombStat(): Promise<TokenStat> {
    const { Zomb3ombLPZShareRewardPool, Zomb3ombRLPZShareRewardPool } = this.contracts;
    const supply = await this.ZOMB.totalSupply();
    const zombRewardPoolSupply = await this.ZOMB.balanceOf(Zomb3ombLPZShareRewardPool.address);
    const zombRewardPoolSupplyRed = await this.ZOMB.balanceOf(Zomb3ombRLPZShareRewardPool.address);
    const zombCirculatingSupply = supply.sub(zombRewardPoolSupply).sub(zombRewardPoolSupplyRed);
    const priceIn2OMB = await this.getTokenPriceFromPancakeswap(this.ZOMB);
    const priceOfOne2OMB = await this.get2OMBMPriceFromPancakeswap();
    const priceOfZombInDollars = (Number(priceIn2OMB) * Number(priceOfOne2OMB)).toFixed(3);

    return {
      tokenInFtm: priceIn2OMB,
      priceInDollars: priceOfZombInDollars,
      totalSupply: getDisplayBalance(supply, this.ZOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(zombCirculatingSupply, this.ZOMB.decimal, 0),
    };
  }

  /**
   * Calculates various stats for the requested LP
   * @param name of the LP token to load stats for
   * @returns
   */
  async getLPStat(name: string): Promise<LPStat> {
    const token = this.v2.getToken(name);
    if (!token) throw new Error(`Cannot find LP Token with name: ${name}`);

    const stats = await this.v2.getLPStats(token);

    return {
      tokenAmount: stats.tokens[0].amount.toFixed(3),
      ftmAmount: stats.tokens[1].amount.toFixed(3),
      priceOfOne: stats.price.toFixed(3),
      totalLiquidity: stats.totalLiquidity.toFixed(3),
      totalSupply: stats.totalSupply.toFixed(3),
    };
  }

  /**
   * Use this method to get price for Zomb
   * @returns TokenStat for zBOND
   * priceInFTM
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getBondStat(): Promise<TokenStat> {
    const { Treasury } = this.contracts;
    const zombStat = await this.getZombStat();
    const bondZombRatioBN = await Treasury.getBondPremiumRate();
    const modifier = bondZombRatioBN / 1e18 > 1 ? bondZombRatioBN / 1e18 : 1;
    const bondPriceInFTM = (Number(zombStat.tokenInFtm) * modifier).toFixed(3);
    const priceOfZBondInDollars = (Number(zombStat.priceInDollars) * modifier).toFixed(3);
    const supply = await this.zBOND.displayedTotalSupply();
    return {
      tokenInFtm: bondPriceInFTM,
      priceInDollars: priceOfZBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  async getShareStat(): Promise<TokenStat> {
    const { ZshareUSDCLPZShareRewardPool } = this.contracts;

    const supply = await this.zSHARE.totalSupply();

    const priceInUSDC = await this.getTokenPriceFromPancakeswap(this.zSHARE);
    const zombStat = await this.getZombStat();
    const pricein3OMB = (Number(priceInUSDC) / Number(zombStat.priceInDollars)).toFixed(4);

    const tombRewardPoolSupply = await this.zSHARE.balanceOf(ZshareUSDCLPZShareRewardPool.address);
    const tShareCirculatingSupply = supply.sub(tombRewardPoolSupply);
    // const priceOfOneFTM = await this.getzSHAREPriceFromPancakeswap();
    // const priceOfSharesInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(3);
    const priceOfSharesInDollars = await this.getTokenPriceFromPancakeswap(this.zSHARE);

    return {
      tokenInFtm: pricein3OMB,
      priceInDollars: priceOfSharesInDollars,
      totalSupply: getDisplayBalance(supply, this.zSHARE.decimal, 0),
      circulatingSupply: getDisplayBalance(tShareCirculatingSupply, this.zSHARE.decimal, 0),
    };
  }

  async getZombFakeStat(): Promise<TokenStat> {
    const { ZombUSDCLPZShareRewardPool } = this.contracts;

    const supply = await this.ZOMB.totalSupply();

    const priceInUSDC = await this.getTokenPriceFromPancakeswap(this.ZOMB);
    const zombStat = await this.getZombStat();
    const pricein3OMB = (Number(priceInUSDC) / Number(zombStat.priceInDollars)).toFixed(4);

    const tombRewardPoolSupply = await this.ZOMB.balanceOf(ZombUSDCLPZShareRewardPool.address);
    const tShareCirculatingSupply = supply.sub(tombRewardPoolSupply);
    // const priceOfOneFTM = await this.getzSHAREPriceFromPancakeswap();
    // const priceOfSharesInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(3);
    // const priceOfSharesInDollars = await this.getTokenPriceFromPancakeswap(this.ZOMB);

    return {
      tokenInFtm: pricein3OMB,
      priceInDollars: zombStat.priceInDollars,
      totalSupply: getDisplayBalance(supply, this.ZOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(tShareCirculatingSupply, this.ZOMB.decimal, 0),
    };
  }

  async getZombStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageOracle, Zomb3ombLPZShareRewardPool } = this.contracts;
    const expectedPrice = await SeigniorageOracle.twap(this.ZOMB.address, ethers.utils.parseEther('1'));

    const supply = await this.ZOMB.totalSupply();
    const zombRewardPoolSupply = await this.ZOMB.balanceOf(Zomb3ombLPZShareRewardPool.address);
    const zombCirculatingSupply = supply.sub(zombRewardPoolSupply);
    return {
      tokenInFtm: getDisplayBalance(expectedPrice),
      priceInDollars: getDisplayBalance(expectedPrice),
      totalSupply: getDisplayBalance(supply, this.ZOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(zombCirculatingSupply, this.ZOMB.decimal, 0),
    };
  }

  async getZombPriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getZombUpdatedPrice();
  }

  async getBondsPurchasable(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBurnableZombLeft();
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRs(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;

    const { depositToken } = bank;
    const poolContract = this.contracts[bank.contract];
    const stakeInPool = await depositToken.balanceOf(bank.address);
    const depositTokenPrice = Number(await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken));

    const TVL = depositTokenPrice * bigToNumber(stakeInPool, depositToken.decimal);

    /** @dev this should always be ZSHARE right now. */
    const tokenPriceInDollars = Number((await this.getShareStat()).priceInDollars);

    const tokenPerSecond = await this.getZshareRewardPerSecond(poolContract, bank);
    const tokenPerHour = tokenPerSecond.mul(60).mul(60);

    const totalRewardPricePerYear =
      tokenPriceInDollars * bigToNumber(tokenPerHour.mul(24).mul(365), depositToken.decimal);
    const totalRewardPricePerDay = tokenPriceInDollars * bigToNumber(tokenPerHour.mul(24), depositToken.decimal);

    const totalStakingTokenInPool = depositTokenPrice * bigToNumber(stakeInPool, depositToken.decimal);

    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;

    return {
      dailyAPR: dailyAPR.toFixed(3),
      yearlyAPR: yearlyAPR.toFixed(3),
      TVL: TVL.toFixed(3),
    };
  }

  /**
   * Method to return the amount of tokens the pool yields per second
   * @param earnTokenName the name of the token that the pool is earning
   * @param contractName the contract of the pool/bank
   * @param poolContract the actual contract of the pool
   * @returns
   */
  async getZshareRewardPerSecond(poolContract: Contract, bank: Bank): Promise<BigNumber> {
    const poolInfo = await poolContract.poolInfo(bank.poolId);
    const totalAlloc = await poolContract.totalAllocPoint();
    const rewardPerSecond: BigNumber = await poolContract.zSharePerSecond();

    return rewardPerSecond.mul(poolInfo.allocPoint).div(totalAlloc);
  }

  /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param pool
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
    const priceOfOneFtmInDollars = await this.get2OMBMPriceFromPancakeswap();
    if (tokenName === 'WFTM') {
      tokenPrice = priceOfOneFtmInDollars;
    } else {
      if (tokenName.startsWith('ZOMB-3OMB')) {
        tokenPrice = await this.getLPTokenPrice(token, this.ZOMB, true, false);
      } else if (tokenName.startsWith('zSHARE-USDC')) {
        tokenPrice = await this.getLPTokenPrice(token, this.zSHARE, false, false);
      } else if (tokenName.startsWith('ZOMB-USDC')) {
        tokenPrice = await this.getLPTokenPrice(token, this.ZOMB, false, true);
      } else if (tokenName.startsWith('ZOMB-ZSHARE')) {
        tokenPrice = await this.getLPTokenPrice(token, this.ZOMB, false, true);
      } else {
        tokenPrice = await this.getTokenPriceFromPancakeswap(token);
        tokenPrice = (Number(tokenPrice) * Number(priceOfOneFtmInDollars)).toString();
      }
    }
    return tokenPrice;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //=========================== END ===================================
  //===================================================================

  async getCurrentEpoch(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.epoch();
  }

  async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBondPremiumRate();
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBonds(amount: string | number): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const treasuryZombPrice = await Treasury.getZombPrice();
    return await Treasury.buyBonds(decimalToBalance(amount), treasuryZombPrice);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBonds(amount: string): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const priceForZomb = await Treasury.getZombPrice();
    return await Treasury.redeemBonds(decimalToBalance(amount), priceForZomb);
  }

  async getTotalValueLocked(): Promise<Number> {
    let totalValue = 0;
    for (const bankInfo of Object.values(bankDefinitions)) {
      const pool = this.contracts[bankInfo.contract];

      const token = this.externalTokens[bankInfo.depositTokenName];
      const tokenPrice = await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token);
      const tokenAmountInPool = await token.balanceOf(pool.address);
      const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
      const poolValue = Number.isNaN(value) ? 0 : value;
      totalValue += poolValue;
    }

    const zSHAREPrice = (await this.getShareStat()).priceInDollars;
    const masonryzShareBalanceOf = await this.zSHARE.balanceOf(this.currentMasonry().address);
    const masonryTVL = Number(getDisplayBalance(masonryzShareBalanceOf, this.zSHARE.decimal)) * Number(zSHAREPrice);

    return totalValue + masonryTVL;
  }

  /**
   * Transpire epoch.
   */
  async transpireEpoch(): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    return await Treasury.allocateSeigniorage();
  }

  /*** Pending epochs.*/
  async pendingEpoch(): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    return await Treasury.nextEpochPoint();
  }

  /**
   * Calculates the price of an LP token
   * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
   * @param lpToken the token under calculation
   * @param token the token pair used as reference (the other one would be FTM in most cases)
   * @param isZomb sanity check for usage of zomb token or zShare
   * @param  isFake @deprecated presumably used on test networks..? we don't have anything so it's going to go
   * @returns price of the LP token
   */
  async getLPTokenPrice(lpToken: ERC20, token: ERC20, isZomb: boolean, isFake: boolean = false): Promise<string> {
    const totalSupply = getFullDisplayBalanceLong(await lpToken.totalSupply(), lpToken.decimal);
    //Get amount of tokenA
    const tokenSupply = getFullDisplayBalance(await token.balanceOf(lpToken.address), token.decimal);
    // const stat = isZomb === true ? await this.getZombStat() : await this.getShareStat();
    const stat =
      isFake === true
        ? isZomb === true
          ? await this.getZombFakeStat()
          : await this.getZombFakeStat()
        : isZomb === true
        ? await this.getZombStat()
        : await this.getShareStat();
    const priceOfToken = stat.priceInDollars;

    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
      .toString();
    return tokenPrice;
  }

  async earnedFromBank(
    poolName: ContractName,
    earnTokenName: String,
    poolId: Number,
    account = this.myAccount,
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      if (earnTokenName === 'ZOMB') {
        return await pool.pendingZOMB(poolId, account);
      } else {
        return await pool.pendingShare(poolId, account);
      }
    } catch (err) {
      console.error(`Failed to call earned() on pool ${pool.address}: ${err}`);
      return BigNumber.from(0);
    }
  }

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      let userInfo = await pool.userInfo(poolId, account);
      return await userInfo.amount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err}`);
      return BigNumber.from(0);
    }
  }

  /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async stake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.deposit(poolId, amount);
  }

  /**
   * Withdraws token from given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.withdraw(poolId, amount);
  }

  /**
   * Transfers earned token reward from given pool to my account.
   */
  async harvest(poolName: ContractName, poolId: Number): Promise<TransactionResponse> {
    console.log(poolName, poolId);
    const pool = this.contracts[poolName];
    //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
    return await pool.withdraw(poolId, 0);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    let userInfo = await pool.userInfo(poolId, account);
    return await pool.withdraw(poolId, userInfo.amount);
  }

  async fetchMasonryVersionOfUser(): Promise<string> {
    return 'latest';
  }

  currentMasonry(): Contract {
    if (!this.masonryVersionOfUser) {
      //throw new Error('you must unlock the wallet to continue.');
    }
    return this.contracts.Masonry;
  }

  isOldMasonryMember(): boolean {
    return this.masonryVersionOfUser !== 'latest';
  }

  async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;
    const { _3OMB, USDC } = this.config.externalTokens;

    // const wftm = new Token(chainId, WFTM[0], WFTM[1]);
    const usdc = new Token(chainId, USDC[0], USDC[1]);
    const _3omb = new Token(chainId, _3OMB[0], _3OMB[1]);
    const token = new Token(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);

    try {
      if (tokenContract.symbol === 'zSHARE') {
        const usdcToToken = await Fetcher.fetchPairData(token, usdc, this.provider);
        const priceInBUSD = new Route([usdcToToken], token);
        return priceInBUSD.midPrice.toFixed(4);
      } else {
        const wftmToToken = await Fetcher.fetchPairData(token, _3omb, this.provider);
        const priceInBUSD = new Route([wftmToToken], token);
        return priceInBUSD.midPrice.toFixed(4);
      }
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  // async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
  //   const ready = await this.provider.ready;
  //   if (!ready) return;
  //   const { chainId } = this.config;

  //   const { WFTM } = this.externalTokens;

  //   const wftm = new TokenSpirit(chainId, WFTM.address, WFTM.decimal);
  //   const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
  //   try {
  //     const wftmToToken = await FetcherSpirit.fetchPairData(wftm, token, this.provider);
  //     const liquidityToken = wftmToToken.liquidityToken;
  //     let ftmBalanceInLP = await WFTM.balanceOf(liquidityToken.address);
  //     let ftmAmount = Number(getFullDisplayBalance(ftmBalanceInLP, WFTM.decimal));
  //     let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
  //     let shibaAmount = Number(getFullDisplayBalance(shibaBalanceInLP, tokenContract.decimal));
  //     const priceOfOneFtmInDollars = await this.get2OMBMPriceFromPancakeswap();
  //     let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
  //     return priceOfShiba.toString();
  //   } catch (err) {
  //     console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
  //   }
  // }

  // async getWFTMPriceFromPancakeswap(): Promise<string> {
  //   const ready = await this.provider.ready;
  //   if (!ready) return;
  //   const { WFTM, FUSDT } = this.externalTokens;
  //   try {
  //     const fusdt_wftm_lp_pair = this.externalTokens['USDT-FTM-LP'];
  //     let ftm_amount_BN = await WFTM.balanceOf(fusdt_wftm_lp_pair.address);
  //     let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
  //     let fusdt_amount_BN = await FUSDT.balanceOf(fusdt_wftm_lp_pair.address);
  //     let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, FUSDT.decimal));
  //     return (fusdt_amount / ftm_amount).toString();
  //   } catch (err) {
  //     console.error(`Failed to fetch token price of WFTM: ${err}`);
  //   }
  // }

  async get2OMBMPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { _3OMB, USDC } = this.externalTokens;
    try {
      const fusdt__3omb_lp_pair = this.externalTokens['USDT-3OMB-LP'];
      let _3omb_amount_BN = await _3OMB.balanceOf(fusdt__3omb_lp_pair.address);
      let _3omb_amount = Number(getFullDisplayBalance(_3omb_amount_BN, _3OMB.decimal));
      let fusdt_amount_BN = await USDC.balanceOf(fusdt__3omb_lp_pair.address);
      let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
      return (fusdt_amount / _3omb_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of 3OMB: ${err}`);
    }
  }

  async getzSHAREPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { USDC } = this.externalTokens;
    try {
      const usdc_zshare_lp_pair = this.externalTokens['zSHARE-USDC-LP'];
      let _zshare_amount_BN = await this.zSHARE.balanceOf(usdc_zshare_lp_pair.address);
      let _zshare_amount = Number(getFullDisplayBalance(_zshare_amount_BN, this.zSHARE.decimal));
      let usdc_amount_BN = await USDC.balanceOf(usdc_zshare_lp_pair.address);
      let usdc_amount = Number(getFullDisplayBalance(usdc_amount_BN, USDC.decimal));
      return (usdc_amount / _zshare_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of 3OMB: ${err}`);
    }
  }

  //===================================================================
  //===================================================================
  //===================== MASONRY METHODS =============================
  //===================================================================
  //===================================================================

  async getMasonryAPR() {
    const Masonry = this.currentMasonry();
    const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
    const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const zSHAREPrice = (await this.getShareStat()).priceInDollars;
    const ZOMBPrice = (await this.getZombStat()).priceInDollars;
    const epochRewardsPerShare = lastRewardsReceived / 1e18;

    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(ZOMBPrice) * 4;
    const masonryzShareBalanceOf = await this.zSHARE.balanceOf(Masonry.address);
    const masonryTVL = Number(getDisplayBalance(masonryzShareBalanceOf, this.zSHARE.decimal)) * Number(zSHAREPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
    return realAPR;
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserClaimRewardFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    return await Masonry.canClaimReward(this.myAccount);
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserUnstakeFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    const canWithdraw = await Masonry.canWithdraw(this.myAccount);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    const notStaked = Number(getDisplayBalance(stakedAmount, this.zSHARE.decimal)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
    // const Masonry = this.currentMasonry();
    // const mason = await Masonry.masons(this.myAccount);
    return BigNumber.from(0);
  }

  async getTotalStakedInMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    return await Masonry.totalSupply();
  }

  async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
    if (this.isOldMasonryMember()) {
      throw new Error("you're using old masonry. please withdraw and deposit the zSHARE again.");
    }
    const Masonry = this.currentMasonry();
    return await Masonry.stake(decimalToBalance(amount));
  }

  async getStakedSharesOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.getShareOf(this.myAccount);
    }
    return await Masonry.balanceOf(this.myAccount);
  }

  async getEarningsOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.getCashEarningsOf(this.myAccount);
    }
    return await Masonry.earned(this.myAccount);
  }

  async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.withdraw(decimalToBalance(amount));
  }

  async harvestCashFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.claimDividends();
    }
    return await Masonry.claimReward();
  }

  async exitFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.exit();
  }

  async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
    const { Treasury } = this.contracts;
    const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
    const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
    const prevAllocation = new Date(Date.now());

    return { from: prevAllocation, to: nextAllocation };
  }

  async getzSHAREPoolStartAndEndTime(): Promise<AllocationTime> {
    const { Zomb3ombLPZShareRewardPool } = this.contracts;
    const startTimestamp: BigNumber = await Zomb3ombLPZShareRewardPool.poolStartTime();
    const endTimestamp: BigNumber = await Zomb3ombLPZShareRewardPool.poolEndTime();
    const startAllocation = new Date(startTimestamp.mul(1000).toNumber());
    const endAllocation = new Date(endTimestamp.mul(1000).toNumber());

    return { from: startAllocation, to: endAllocation };
  }
  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to claim
   * their reward from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserClaimRewardTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
    const rewardLockupEpochs = await Masonry.rewardLockupEpochs();
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

    const fromDate = new Date(Date.now());
    if (targetEpochForClaimUnlock - currentEpoch <= 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - currentEpoch - 1;
      const endDate = moment(toDate)
        .add(delta * periodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to unstake
   * from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserUnstakeTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint();
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const PeriodInHours = period / 60 / 60;
    const withdrawLockupEpochs = await Masonry.withdrawLockupEpochs();
    const fromDate = new Date(Date.now());
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * PeriodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  async watchAssetInMetamask(assetName: string): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
      if (assetName === 'ZOMB') {
        asset = this.ZOMB;
        assetUrl = 'https://zombfinance.com/static/presskit/crypto_zomb_cash.png';
      } else if (assetName === 'zSHARE') {
        asset = this.zSHARE;
        assetUrl = 'https://zombfinance.com/static/presskit/crypto_zomb_share.png';
      } else if (assetName === 'zBOND') {
        asset = this.zBOND;
        assetUrl = 'https://zombfinance.com/static/presskit/crypto_zomb_bond.png';
      }
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }

  async provideZombFtmLP(ftmAmount: string, zombAmount: BigNumber): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(ftmAmount, 18),
    };
    return await TaxOffice.addLiquidityETHTaxFree(
      zombAmount,
      zombAmount.mul(992).div(1000),
      parseUnits(ftmAmount, 18).mul(992).div(1000),
      overrides,
    );
  }

  async quoteFromSpooky(tokenAmount: string, tokenName: string): Promise<string> {
    const { SpookyRouter } = this.contracts;
    const { _reserve0, _reserve1 } = await this.ZOMBWFTM_LP.getReserves();
    let quote;
    if (tokenName === 'ZOMB') {
      quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
    } else {
      quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
    }
    return (quote / 1e18).toString();
  }

  /**
   * @returns an array of the regulation events till the most up to date epoch
   */
  async listenForRegulationsEvents(): Promise<any> {
    const { Treasury } = this.contracts;

    const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
    const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
    const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
    const boughzBondsFilter = Treasury.filters.BoughzBonds();
    const redeemBondsFilter = Treasury.filters.RedeemedBonds();

    let epochBlocksRanges: any[] = [];
    let masonryFundEvents = await Treasury.queryFilter(treasuryMasonryFundedFilter);
    var events: any[] = [];
    masonryFundEvents.forEach(function callback(value, index) {
      events.push({ epoch: index + 1 });
      events[index].masonryFund = getDisplayBalance(value.args[1]);
      if (index === 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
      }
      if (index > 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
        epochBlocksRanges[index - 1].endBlock = value.blockNumber;
      }
    });

    epochBlocksRanges.forEach(async (value, index) => {
      events[index].bondsBought = await this.getBondsWithFilterForPeriod(
        boughzBondsFilter,
        value.startBlock,
        value.endBlock,
      );
      events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
        redeemBondsFilter,
        value.startBlock,
        value.endBlock,
      );
    });
    let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
    DEVFundEvents.forEach(function callback(value, index) {
      events[index].devFund = getDisplayBalance(value.args[1]);
    });
    let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
    DAOFundEvents.forEach(function callback(value, index) {
      events[index].daoFund = getDisplayBalance(value.args[1]);
    });
    return events;
  }

  /**
   * Helper method
   * @param filter applied on the query to the treasury events
   * @param from block number
   * @param to block number
   * @returns the amount of bonds events emitted based on the filter provided during a specific period
   */
  async getBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
    const { Treasury } = this.contracts;
    const bondsAmount = await Treasury.queryFilter(filter, from, to);
    return bondsAmount.length;
  }

  /**
   * Get the 2 tokens that are contained in an LP, given the LP ERC20 object or its name
   * NOTE: This is a quick hack until we start refactoring config/interfaces and can
   * have this information in there.
   * For now this will have to be modified every time we add a new LP
   * @param lpToken name of LP token to evaluate
   * @returns array containing the two tokens in the LP
   */
  getTokensInLpPair(lpToken: string): [ERC20, ERC20] {
    return this.v2.getTokensFromLP(lpToken);
  }

  async estimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]>;
  async estimateZapIn(zapToken: ERC20, lpToken: ERC20, amount: string, _router?: string): Promise<number[]>;
  /**
   * Get the estimate for the tokens contained in the LP given the amount of _token_ provided.
   * @param token token to zap in
   * @param lp LP to zap into
   * @param amount how much <token> we want to zap
   * @param _router @optional DEX we're using. only works when providing ERC20 objects for now
   * @returns Estimates for the amount of tokens in the LP
   */
  async estimateZapIn(
    token: string | ERC20,
    lp: string | ERC20,
    amount: string,
    _router: string = ADDRESS.SPOOKY_ROUTER,
  ): Promise<number[]> {
    if (token instanceof ERC20 && lp instanceof ERC20) {
      console.debug(`✔️ using zap estimates v2 for ${lp.symbol}`);
      const [, estimate] = await this.v2.estimateZapIn(token, lp, amount, _router);
      // this is to comply with the signature, but will obviously change.
      return [estimate.tokens[0].amount, estimate.tokens[1].amount];
    }

    if (typeof token === 'string' && typeof lp === 'string') {
      console.debug(`❌ using old estimates for ${lp}`);
      return this.__oldEstimateZapIn(token, lp, amount);
    }
  }

  async __oldEstimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
    const { zapper } = this.contracts;
    const lpToken = this.getToken(lpName);
    let estimate;
    if (tokenName === SYMBOLS.FTM) {
      estimate = await zapper.estimateZapIn(lpToken.address, ADDRESS.SPOOKY_ROUTER, parseUnits(amount, 18));
    } else {
      const token = tokenName === SYMBOLS.ZOMB ? this.ZOMB : this.zSHARE;
      estimate = await zapper.estimateZapInToken(
        token.address,
        lpToken.address,
        ADDRESS.SPOOKY_ROUTER,
        parseUnits(amount, 18),
      );
    }
    return [estimate[0] / 1e18, estimate[1] / 1e18];
  }
  async zapIn(
    tokenName: string | ERC20,
    lpName: string | ERC20,
    amount: string | number,
  ): Promise<TransactionResponse> {
    const { zapper } = this.contracts;
    amount = String(amount);
    // V2 implementation
    if (tokenName instanceof ERC20 && lpName instanceof ERC20) {
      return this.v2.zapIn(tokenName, lpName, amount);
    }

    const lpToken = this.externalTokens[lpName as string];
    if (tokenName === SYMBOLS.FTM) {
      let overrides = {
        value: parseUnits(amount, 18),
      };
      return await zapper.zapIn(lpToken.address, ADDRESS.SPOOKY_ROUTER, this.myAccount, overrides);
    } else {
      const token = tokenName === SYMBOLS.ZOMB ? this.ZOMB : this.zSHARE;
      return await zapper.zapInToken(
        token.address,
        parseUnits(amount, 18),
        lpToken.address,
        ADDRESS.SPOOKY_ROUTER,
        this.myAccount,
      );
    }
  }

  async swapZBondToZShare(zbondAmount: BigNumber): Promise<TransactionResponse> {
    const { ZShareSwapper } = this.contracts;
    return await ZShareSwapper.swapZBondToZShare(zbondAmount);
  }
  async estimateAmountOfZShare(zbondAmount: string): Promise<string> {
    const { ZShareSwapper } = this.contracts;
    try {
      const estimateBN = await ZShareSwapper.estimateAmountOfZShare(parseUnits(zbondAmount, 18));
      return getDisplayBalance(estimateBN, 18, 6);
    } catch (err) {
      console.error(`Failed to fetch estimate zshare amount: ${err}`);
    }
  }

  async getZShareSwapperStat(address: string): Promise<ZShareSwapperStat> {
    const { ZShareSwapper } = this.contracts;
    const zshareBalanceBN = await ZShareSwapper.getZShareBalance();
    const zbondBalanceBN = await ZShareSwapper.getZBondBalance(address);
    // const zombPriceBN = await ZShareSwapper.getZombPrice();
    // const zsharePriceBN = await ZShareSwapper.getZSharePrice();
    const rateZSharePerZombBN = await ZShareSwapper.getZShareAmountPerZomb();
    const zshareBalance = getDisplayBalance(zshareBalanceBN, 18, 5);
    const zbondBalance = getDisplayBalance(zbondBalanceBN, 18, 5);
    return {
      zshareBalance: zshareBalance.toString(),
      zbondBalance: zbondBalance.toString(),
      // zombPrice: zombPriceBN.toString(),
      // zsharePrice: zsharePriceBN.toString(),
      rateZSharePerZomb: rateZSharePerZombBN.toString(),
    };
  }
}
